.seat-map-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.section-filter-select {
  width: 100%;
}
.pinch-zoom-wrapper {
  height: 40%;
  max-height: 100%;
  width: 100% !important;
  flex: 1;
  cursor: grab;
}
.pinch-zoom-content {
  height: 100% !important;
  width: 100% !important;
}
@media screen and (min-width: 990px) {
  .pinch-zoom-wrapper {
    height: 100% !important;
    width: 50%;
  }
}

@media (max-height: 46rem) {
  .seatmap-iframe {
    height: 600px !important;
  }
}

@media (min-height: 46rem) {
  .seatmap-iframe {
    height: 900px !important;
  }
}